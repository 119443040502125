main {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-image: url('../src/assets/images/newFon/17.jpeg'); /* Путь к вашему изображению */
  background-repeat: repeat;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fons/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: url('./assets/fons/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.montserrat-regular {
  font-family: 'Montserrat', sans-serif;
}

.montserrat-bold {
  font-family: 'MontserratBold', sans-serif;
}